export default class Schedule {
  constructor() {
    const navHeaders = $('.schedule-day__header');
    const dayList = $('.schedule-nav__list');

    this.days = $('.schedule-day')
    this.dayLength = this.days.length;

    this.events = $('.schedule__events');

    this.listDays = $('.schedule-nav__list li')

    const prevDay = $('.schedule-nav__prev');
    const nextDay = $('.schedule-nav__next');

    navHeaders.on('click', (e) => {
      navHeaders.toggleClass('open');
      dayList.stop().slideToggle(400);
    });

    prevDay.on('click', (e) => {
      this.showPrevDay();
    });

    nextDay.on('click', (e) => {
      this.showNextDay();
    });

    this.listDays.find('a').on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.goToDay(target);
    });
  }

  showPrevDay() {
    const activeDayIndex = $('.schedule-day.active').index();
    const prevIndex = activeDayIndex - 1 >= 0 ? activeDayIndex - 1 : this.dayLength - 1;

    this.setActiveState(this.days, prevIndex);
    this.setActiveState(this.listDays, prevIndex);
    this.setActiveState(this.events, prevIndex);
  }

  showNextDay() {
    const activeDayIndex = $('.schedule-day.active').index();
    const nextIndex = activeDayIndex + 1 < this.dayLength ? activeDayIndex + 1 : 0;

    this.setActiveState(this.days, nextIndex);
    this.setActiveState(this.listDays, nextIndex);
    this.setActiveState(this.events, nextIndex);
  }

  goToDay(target) {
    const parent = target.parent();
    const targetIndex = parent.index();

    this.listDays.removeClass('active');
    parent.addClass('active');

    this.setActiveState(this.days, targetIndex);
    this.setActiveState(this.events, targetIndex);
  }

  setActiveState(elements, index) {
    elements.removeClass('active');
    elements.eq(index).addClass('active');
  }
}