export default class People {
  constructor() {
    const personButtons = document.querySelectorAll(".person")

    personButtons.forEach((btn) => {
      const dialogId = btn.dataset.dialog
      const dialog = document.querySelector(`#${dialogId}`)
      const close = dialog.querySelector(".bio-dialog__close")

      const addESC = (e) => {
        if (e.keyCode === 27) {
          closeDialog()
        }
      }

      btn.addEventListener("click", () => {
        dialog.removeAttribute("hidden")
        dialog.classList.add("open")
        dialog.focus()

        close.removeAttribute("tabindex")

        document.addEventListener("keydown", addESC)
      })

      close.addEventListener("click", () => {
        closeDialog()
      })

      const closeDialog = () => {
        dialog.classList.remove("open")
        close.setAttribute("tabindex", "-1")

        setTimeout(() => {
          dialog.setAttribute("hidden", "")
        }, 400)

        btn.focus()

        document.removeEventListener("keydown", addESC)
      }
    })

    const prevArrow = `
      <button type="button" class="gallery__nav gallery__prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.0263 71.188">
          <path d="M13.2788,35.5939l23.294-23.295a8.4348,8.4348,0,0,0,0-11.894,1.3785,1.3785,0,0,0-1.952,0L.4828,34.5419A1.4281,1.4281,0,0,0,0,35.5939a1.3756,1.3756,0,0,0,.404.98l34.217,34.209a1.3785,1.3785,0,0,0,1.952,0,8.436,8.436,0,0,0,0-11.895Z" transform="translate(0.0002 0.0001)"/>
        </svg>
    `

    const nextArrow = `
      <button type="button" class="gallery__nav gallery__next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.0263 71.188">
          <path d="M26.7212,35.5939,3.4272,12.2989a8.4348,8.4348,0,0,1,0-11.894,1.3785,1.3785,0,0,1,1.952,0l34.138,34.137A1.4281,1.4281,0,0,1,40,35.5939a1.3756,1.3756,0,0,1-.404.98L5.3792,70.7829a1.3785,1.3785,0,0,1-1.952,0,8.436,8.436,0,0,1,0-11.895Z" transform="translate(-0.974 0.0001)"/>
        </svg>
      </button>
    `

    const sliders = document.querySelectorAll(".people-slider")

    sliders.forEach((slider) => {
      const buttons = slider.querySelectorAll("button")

      $(slider).slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: prevArrow,
        nextArrow: nextArrow,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      })

      $(slider).on("beforeChange", () => {
        buttons.forEach((button) => {
          button.setAttribute("disabled", "")
        })
      })

      $(slider).on("afterChange", () => {
        buttons.forEach((button) => {
          button.removeAttribute("disabled")
        })
      })
    })
  }
}
