export default class Blog {
  constructor() {
    this.searchForm = $('#blog-search');
    this.categories = this.searchForm.find('select');
    this.keyword = this.searchForm.find('input');

    this.searchForm.on('submit', (e) => {
      e.preventDefault();
    });

    this.searchForm.find('select').on('change', () => {
      this.filterPosts();
    });

    this.searchForm.find('input').on('change', () => {
      this.filterPosts();
    });
  }

  filterPosts() {
    $.ajax({
      url: '/blog.js',
      method: 'get',
      data: {
        category: this.categories.val(),
        keyword: this.keyword.val(),
        page: 1
      }
    });
  }
}