import "jquery-hoverintent"

export default class Menu {
  constructor() {
    this.header = $(".header")
    this.allSubnavs = $(".subnav")
    this.backdrop = $(".subnav-backdrop")
    this.headerToggle = $(".header__toggle")

    // adds has-subnav class where necessary
    this.mobileMenuItems = $.makeArray(
      $(".mobile .main-menu > li"),
      $(".festival-menu > li"),
    )

    Array.from(this.mobileMenuItems).forEach((item) => {
      if ($(item).children("ul").length > 0) {
        $(item).addClass("has-subnav")
      }
    })

    // hamburger wraps do not sound delicious
    this.hamburger = $(".header__hamburger--wrap")
    this.festivalHamburger = $(".festival-navigation__hamburger--wrap")

    // mobile festival menu open/close
    this.festivalHamburger.on("click touchend", (e) => {
      if (e.handled === false) return
      e.stopPropagation()
      e.preventDefault()
      e.handled = true

      this.openFestivalMobileMenu()

      this.festivalHamburger.children().toggleClass("open")

      if (this.headerToggle.hasClass("close")) {
        this.toggleMobileHeader()
      }
    })

    // mobile menu open/close
    this.hamburger.on("click touchend", (e) => {
      if (e.handled === false) return
      e.stopPropagation()
      e.preventDefault()
      e.handled = true

      this.openMobileMenu()
      this.hamburger.children().toggleClass("open")
    })

    this.dropdown = $(".default__sidebar__title")

    // dropdown mobile menu open/close
    this.dropdown.on("click", (e) => {
      this.openDropdownMobileMenu()
      this.dropdown.toggleClass("open")
    })

    this.multiFestDropdown = $(".film__year-link > span")

    // dropdown mobile menu open/close
    this.multiFestDropdown.on("click", (e) => {
      this.openMultiFestDropdownMobileMenu()
      this.multiFestDropdown.toggleClass("open")
    })

    this.mobileNav = $.makeArray(
      $(".mobile .main-menu > li > button"),
      $(".mobile .main-menu .festival-list > span"),
    )

    this.mobileNav.on("click touchend", (e) => {
      if (e.handled === false) return
      e.stopPropagation()
      e.preventDefault()
      e.handled = true

      const clicked = $(e.currentTarget)

      if (
        $(clicked).siblings("ul").length > 0 &&
        !$(clicked).siblings("ul").hasClass("open")
      ) {
        $(clicked).parent().addClass("open")
        $(clicked).siblings("ul").addClass("open").slideToggle()

        if ($(clicked).parent().siblings().hasClass("open")) {
          $(clicked).parent().siblings().removeClass("open")
          let children = $(clicked).parent().siblings().children()
          Array.from(children).forEach((item) => {
            if ($(item).hasClass("open")) {
              $(item).removeClass("open").slideToggle()
            }
          })
        }
      } else {
        $(clicked).parent().removeClass("open")
        $(clicked).siblings("ul").removeClass("open").slideToggle()
      }
    })

    // main menu link hover
    const links = document.querySelectorAll(
      ".main-menu > li > button, .main-menu > li > span",
    )
    Array.from(links).forEach((link) => {
      let linkage = link
      $(linkage).hoverIntent(
        () => {
          this.showSubnav(link)
        },
        () => {},
      )
    })

    $(window).on("resize", () => {
      this.resizeSubNav()
    })

    // main menu link focus
    $(".main-menu button").on("focus", (e) => {
      const target = $(e.currentTarget)

      if (target.next().hasClass("subnav")) {
        this.showSubnav(target[0])
      } else if (target.parents(".subnav").length) {
        const parentLink = target.parents(".subnav").prev()
        this.showSubnav(parentLink[0])
      } else {
        this.allSubnavs.removeClass("show")
        this.backdrop.css("height", 0)
      }
    })

    // top bar hover
    const topBar = document.querySelector(".header__top-bar")
    this.hideSubnavs(topBar)

    // top bar focus
    $(".header__top-bar a").on("focus", () => {
      this.allSubnavs.removeClass("show")
      this.backdrop.css("height", 0)
    })

    // main hover
    const main = document.querySelector("main")
    this.hideSubnavs(main)

    // main focus
    $("main a, main button").on("focus", () => {
      this.allSubnavs.removeClass("show")
      this.backdrop.css("height", 0)
    })

    jQuery.extend(jQuery.easing, {
      def: "easeOutQuad",
      easeOutQuad: function (x, t, b, c, d) {
        return -c * (t /= d) * (t - 2) + b
      },
    })

    // header size toggle
    this.headerToggle.on("click", (e) => {
      if ($(e.currentTarget).parent().hasClass("desktop")) {
        this.toggleHeader()
      } else {
        this.toggleMobileHeader()

        if (
          this.festivalHamburger
            .find(".festival-navigation__hamburger")
            .hasClass("open")
        ) {
          this.openFestivalMobileMenu()
          this.festivalHamburger
            .find(".festival-navigation__hamburger")
            .toggleClass("open")
        }
      }
    })

    $(window).resize((e) => {
      const mobileMenu = $(".default__menu")

      if (window.innerWidth > 960) {
        mobileMenu.slideDown()
      }
    })
  }

  openMobileMenu() {
    const mobileMenu = $(".mobile .main-menu")

    mobileMenu.slideToggle()
  }

  openMultiFestDropdownMobileMenu() {
    const mobileMenu = $(".film__year-link--wrap")

    mobileMenu.slideToggle()
  }

  openDropdownMobileMenu() {
    const mobileMenu = $(".default__menu")

    mobileMenu.slideToggle()
  }

  openFestivalMobileMenu() {
    const mobileMenu = $(".festival-navigation .festival-menu")

    mobileMenu.slideToggle()
  }

  showSubnav(link) {
    const subnav = $(link).next(".subnav")

    if (subnav.length) {
      const subnavHeight = subnav.outerHeight()

      this.allSubnavs.removeClass("show")
      subnav.addClass("show")
      this.backdrop.css("height", subnavHeight + 62)
    } else {
      this.allSubnavs.removeClass("show")
      this.backdrop.css("height", 0)
    }
  }

  resizeSubNav() {
    const subnav = $(".subnav.show")

    if (subnav.length) {
      const subnavHeight = subnav.outerHeight()
      this.backdrop.css("height", subnavHeight + 62)
    }
  }

  hideSubnavs(element) {
    let item = element
    $(item).hoverIntent(
      () => {
        this.allSubnavs.removeClass("show")
        this.backdrop.css("height", 0)
      },
      () => {},
    )
  }

  toggleHeader() {
    this.header.toggleClass("header--small")
    this.headerToggle.toggleClass("close")
    $(".header nav.desktop").slideToggle(300, "easeOutQuad")
  }

  toggleMobileHeader() {
    this.header.toggleClass("header--small")
    this.headerToggle.toggleClass("close")
    $(".header nav.mobile").slideToggle(300, "easeOutQuad")
  }
}
