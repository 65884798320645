jQuery.expr[":"].regex = function (elem, index, match) {
  var matchParams = match[3].split(","),
    validLabels = /^(data|css):/,
    attr = {
      method: matchParams[0].match(validLabels)
        ? matchParams[0].split(":")[0]
        : "attr",
      property: matchParams.shift().replace(validLabels, ""),
    },
    regexFlags = "ig",
    regex = new RegExp(
      matchParams.join("").replace(/^\s+|\s+$/g, ""),
      regexFlags,
    )
  return regex.test(jQuery(elem)[attr.method](attr.property))
}

import "core-js/es6"
import "regenerator-runtime/runtime"
import objectFitImages from "object-fit-images"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Accordions from "./accordions"
import Blog from "./blog"
import Calendar from "./calendar"
import Collage from "./collage"
import Dropdown from "./dropdown"
import Gallery from "./gallery"
import HeroSlider from "./hero-slider"
import JumpLinks from "./jump-links"
import LogoInteraction from "./logo_interaction"
import Menu from "./menu"
import People from "./people"
import Scroll from "./scroll"
import Schedule from "./schedule"
import Search from "./search"
import Sidebars from "./sidebars"
import Video from "./video"

new Accordions()
new Blog()
new Calendar()
new Collage()
new Dropdown()
new Gallery()
new HeroSlider()
new JumpLinks()
new LogoInteraction()
new Menu()
new People()
new Scroll()
new Schedule()
new Search()
new Sidebars()
new Video()

objectFitImages()
