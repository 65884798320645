export default class LogoInteraction {
  constructor() {
    $(".header__logo-icon a").on('click', (e) => {
      e.preventDefault();
      var href = $(e.currentTarget || e.target).attr('href');
      $(".header__logo-icon").toggleClass('active');

      setTimeout(function(){
        window.location = href;
      }, 600);
    });
  }
}