export default class Collage {
  constructor() {
    $(window).on('load', () => {
      $('.collage__gallery').each((index, gallery) => {
        this.initCollageGallery($(gallery));
      });
    });
  }

  initCollageGallery(gallery) {
    let slickOptions = {
      accessibility: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      centerMode: true,
      cssEase: 'linear',
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      rows: 1,
      speed: 20000,
      swipe: false,
      touchMove: false,
      variableWidth: true
    };

    if (gallery.hasClass('collage__gallery--reverse')) {
      slickOptions.rtl = true;
      slickOptions.centerMode = false;
    }

    gallery.slick(slickOptions);
  }
}
