export default class Dropdown {
  constructor() {
    let selects = $('select');

    $.each(selects, (index, e) => {
      let options = $(e).children();

      $(e).on('change', (x) => {
        if (options.index($(e).find(':selected')) == 0) {
          $(e).addClass('placeholder');
        } else {
          $(e).removeClass('placeholder');
        }
      });
    });
  }
}
