export default class JumpLinks {
  constructor() {
    const anchors = document.querySelectorAll(".anchor")
    const jumpLinkBlocks = document.querySelectorAll(".jump-links")

    if (anchors.length > 0 && jumpLinkBlocks.length > 0) {
      let anchorLinks = "<nav><ul>"
      anchors.forEach((anchor, index) => {
        if (index === 0) {
          anchorLinks += `
            <li><a href="#${anchor.id}">${anchor.name}</a></li>
          `
        } else {
          anchorLinks += `
            <span>/</span>
            <li><a href="#${anchor.id}">${anchor.name}</a></li>
          `
        }
      })
      anchorLinks += "</ul></nav>"

      jumpLinkBlocks.forEach((block) => {
        block.innerHTML = anchorLinks
      })
    }
  }
}
