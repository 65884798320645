export default class HeroSlider {
  constructor() {
    this.heroGalleries = $(".hero-slider")

    this.prevArrow = `
      <button type="button" class="gallery__nav gallery__prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.0263 71.188">
          <path d="M13.2788,35.5939l23.294-23.295a8.4348,8.4348,0,0,0,0-11.894,1.3785,1.3785,0,0,0-1.952,0L.4828,34.5419A1.4281,1.4281,0,0,0,0,35.5939a1.3756,1.3756,0,0,0,.404.98l34.217,34.209a1.3785,1.3785,0,0,0,1.952,0,8.436,8.436,0,0,0,0-11.895Z" transform="translate(0.0002 0.0001)"/>
        </svg>
    `

    this.nextArrow = `
      <button type="button" class="gallery__nav gallery__next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.0263 71.188">
          <path d="M26.7212,35.5939,3.4272,12.2989a8.4348,8.4348,0,0,1,0-11.894,1.3785,1.3785,0,0,1,1.952,0l34.138,34.137A1.4281,1.4281,0,0,1,40,35.5939a1.3756,1.3756,0,0,1-.404.98L5.3792,70.7829a1.3785,1.3785,0,0,1-1.952,0,8.436,8.436,0,0,1,0-11.895Z" transform="translate(-0.974 0.0001)"/>
        </svg>
      </button>
    `

    this.initSliders()
  }

  initSliders() {
    this.heroGalleries.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: this.prevArrow,
      nextArrow: this.nextArrow,
    })
  }
}
