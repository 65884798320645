export default class Accordions {
  constructor() {
    const accordions = document.querySelectorAll(".accordion")

    accordions.forEach((accordion) => {
      const trigger = accordion.querySelector(".accordion__trigger")
      const content = accordion.querySelector(".accordion__content")

      trigger.addEventListener("click", () => {
        trigger.classList.toggle("open")
        content.toggleAttribute("hidden")
      })
    })
  }
}
