export default class Search {
  constructor() {
    this.icon = $('#search-icon');
    this.close = $('#search-close');
    this.overlay = $('#search');

    this.icon.on('click', (e) => {
      this.overlay.stop().fadeIn(400);
      this.overlay.find('input').focus();
    });

    this.close.on('click', (e) => {
      this.overlay.stop().fadeOut(400);
    });
  }
}