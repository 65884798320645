import ScrollMagic from 'scrollmagic';

export default class Sidebars {
  constructor() {
    this.controller = new ScrollMagic.Controller();
    this.feeds = $('.feeds');

    if (this.feeds.length > 0) {
      var durationSet = $('.feeds__content').height() - $('.feeds-sidebar').height() - 67;

      if (durationSet > 0) {
        this.scene = new ScrollMagic.Scene({
          triggerElement: '.feeds',
          offset: $(window).height() / 2,
          duration: durationSet,
        }).setPin('.feeds-sidebar', { pushFollowers: false })
          .addTo(this.controller);
      };
    }

    $(window).on('load resize', () => {
      const windowWidth = window.innerWidth;

      if (this.feeds.length > 0) {
        const feedSidebarHeight = $('.feeds-sidebar').height();
        const feedContentHeight = $('.feeds__content').height();

        if (windowWidth < 960 && durationSet >= 0 || feedSidebarHeight > feedContentHeight && durationSet >= 0) {
          this.scene.enabled(false);
        } else if (durationSet >= 0) {
          this.scene.enabled(true);
        }
      }
    });
  }
}
